body, html {
    margin: 0;
    padding: 0;
    background-color: #fff;
}

img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}
.pan-zoom-element {
    border: 1px solid #ccc;
    position: relative;
    overflow: hidden;
    cursor: default;
}
.pan-zoom-element .content-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.pan-zoom-element .noselect {
    -webkit-touch-callout: none;
   /* iOS Safari */
    -webkit-user-select: none;
   /* Chrome/Safari/Opera */
    -khtml-user-select: none;
   /* Konqueror */
    -moz-user-select: none;
   /* Firefox */
    -ms-user-select: none;
   /* Internet Explorer/Edge */
    user-select: none;
   /* Non-prefixed version, currently not supported by any browser */
}
#activeButton svg text {
    fill: #000!important;
}
@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 20deg);
   }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
   }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
   }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
   }
    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
   }
}
@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
   }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
   }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
   }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
   }
    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
   }
}

.hidden{
    display:none;
}

#root {
    background-color:#EAEAEA;
    min-height: 100svh;
    /*min-height: 100svh;*/
}

body > iframe {

    border-top: 50px solid red !important;
    border-left:50px solid red!important;
    border-radius: 20px;
    transition: all 300ms;
    transform: translateY(80vh);
    opacity:0.7!important;
}
body > iframe:hover {
    transform: translateY(10vh);
    opacity:1!important;
}

